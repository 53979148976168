import React from "react"
import { StaticQuery, graphql } from "gatsby"


export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksPricing{
          edges {
            node {
             boat {
              file {
                url
              }
             },
             miniBoat{
                file{
                 url
                }
             },
             ship {
                file{
                 url
                }
             }
             column1Title,
             column2Title,
             column3Title,
             column1,
             column2,
             column3,
             column1Price,
             column2Price,
             column3Price

            }
          }    
        }
     }
    `}
render={data => (

<section data-id="6ae183e" className="elementor-element elementor-element-6ae183e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" id="pricing" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
            <div data-id="a108717" className="elementor-element elementor-element-a108717 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                <div className="elementor-column-wrap  elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <div data-id="41ca28c" className="elementor-element elementor-element-41ca28c elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                        <section data-id="3e15b7e" className="elementor-element elementor-element-3e15b7e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                    <div data-id="9755941" className="elementor-element elementor-element-9755941 elementor-column elementor-col-33 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="64fb472" className="elementor-element elementor-element-64fb472 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="51e8a7f" className="elementor-element elementor-element-51e8a7f elementor-widget elementor-widget-image" data-element_type="image.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image">
                                                            <img width="178" height="178" src={data.allContentfulOuticksPricing.edges[0].node.miniBoat.file.url} className="attachment-large size-large" alt="mini boat 1" /> </div>
                                                    </div>
                                                </div>
                                                <div data-id="4f73a31" className="elementor-element elementor-element-4f73a31 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h5 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksPricing.edges[0].node.column1Title}</h5>
                                                    </div>
                                                </div>
                                                <div data-id="f8eaf3c" className="elementor-element elementor-element-f8eaf3c elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-divider">
                                                            <span className="elementor-divider-separator"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    data.allContentfulOuticksPricing.edges[0].node.column1.map( x => (
                                                        <>
                                                        <div data-id="41ec55e" className="elementor-element elementor-element-41ec55e pricing-feature-row elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-text-editor elementor-clearfix">
                                                                    <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: x.replace(/[^\s]*/, '<strong>' + x.split(' ')[0] + '</strong>')}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-id="87017a7" className="elementor-element elementor-element-87017a7 elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-divider">
                                                                    <span className="elementor-divider-separator"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ))
                                                }

                                                <div data-id="a83baa8" class="elementor-element elementor-element-a83baa8 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="elementor-text-editor elementor-clearfix" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: data.allContentfulOuticksPricing.edges[0].node.column1Price}} />

                                                    </div>
                                                </div>
                                                
                                                <div data-id="c94ffb2" className="elementor-element elementor-element-c94ffb2 elementor-align-center elementor-widget elementor-widget-button" data-element_type="button.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-button-wrapper">
                                                            <a href="https://app.outicks.com/account/register?plan=team" className="elementor-button-link elementor-button elementor-size-md" role="button">
                                                                <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-text">Start Free Trial</span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="07226b8" className="elementor-element elementor-element-07226b8 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-id="68d463f" className="elementor-element elementor-element-68d463f elementor-column elementor-col-33 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="3b8daa9" className="elementor-element elementor-element-3b8daa9 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="fe3af0b" className="elementor-element elementor-element-fe3af0b elementor-widget elementor-widget-image" data-element_type="image.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image">
                                                            <img width="178" height="178" src={data.allContentfulOuticksPricing.edges[0].node.boat.file.url} className="attachment-large size-large" alt="boat" /> </div>
                                                    </div>
                                                </div>
                                                <div data-id="c3411aa" className="elementor-element elementor-element-c3411aa elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h5 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksPricing.edges[0].node.column2Title}</h5>
                                                    </div>
                                                </div>
                                                <div data-id="f8eaf3c" className="elementor-element elementor-element-f8eaf3c elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-divider">
                                                            <span className="elementor-divider-separator"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    data.allContentfulOuticksPricing.edges[0].node.column2.map( x => (
                                                        <>
                                                        <div data-id="41ec55e" className="elementor-element elementor-element-41ec55e pricing-feature-row elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-text-editor elementor-clearfix">
                                                                    <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: x.replace(/[^\s]*/, '<strong>' + x.split(' ')[0] + '</strong>')}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-id="87017a7" className="elementor-element elementor-element-87017a7 elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-divider">
                                                                    <span className="elementor-divider-separator"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ))
                                                }
                                                
                                                <div data-id="a83baa8" class="elementor-element elementor-element-a83baa8 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="elementor-text-editor elementor-clearfix" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: data.allContentfulOuticksPricing.edges[0].node.column2Price}} />

                                                    </div>
                                                </div>

                                                <div data-id="bae23f5" className="elementor-element elementor-element-bae23f5 elementor-align-center elementor-widget elementor-widget-button" data-element_type="button.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-button-wrapper">
                                                            <a href="https://app.outicks.com/account/register?plan=business" className="elementor-button-link elementor-button elementor-size-md" role="button">
                                                                <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-text">Start Free Trial</span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="8173e25" className="elementor-element elementor-element-8173e25 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-id="11a46a0" className="elementor-element elementor-element-11a46a0 elementor-column elementor-col-33 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="e6e7a40" className="elementor-element elementor-element-e6e7a40 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="a87ca40" className="elementor-element elementor-element-a87ca40 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image">
                                                            <img width="178" height="178" src={data.allContentfulOuticksPricing.edges[0].node.ship.file.url} className="attachment-large size-large" alt="ship"/> </div>
                                                    </div>
                                                </div>
                                                <div data-id="73a8659" className="elementor-element elementor-element-73a8659 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h5 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksPricing.edges[0].node.column3Title}</h5>
                                                    </div>
                                                </div>
                                                <div data-id="e11bea7" className="elementor-element elementor-element-e11bea7 elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-divider">
                                                            <span className="elementor-divider-separator"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    data.allContentfulOuticksPricing.edges[0].node.column3.map( x => (
                                                        <>
                                                        <div data-id="41ec55e" className="elementor-element elementor-element-41ec55e pricing-feature-row elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: x.replace(/[^\s]*/, '<strong>' + x.split(' ')[0] + '</strong>')}}/>

                                                            </div>
                                                        </div>
                                                        <div data-id="87017a7" className="elementor-element elementor-element-87017a7 elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-divider">
                                                                    <span className="elementor-divider-separator"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ))
                                                }   

                                                <div data-id="a83baa8" class="elementor-element elementor-element-a83baa8 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="elementor-text-editor elementor-clearfix" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: data.allContentfulOuticksPricing.edges[0].node.column3Price}} />
                                                    
                                                    </div>
                                                </div>

                                                <div data-id="85f9b11" className="elementor-element elementor-element-85f9b11 elementor-align-center elementor-widget elementor-widget-button" data-element_type="button.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-button-wrapper">
                                                            <a href="https://app.outicks.com/account/register?plan=enterprise" className="elementor-button-link elementor-button elementor-size-md" role="button">
                                                                <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-text">Start Free Trial</span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="f4f87d4" className="elementor-element elementor-element-f4f87d4 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div data-id="a422974" className="elementor-element elementor-element-a422974 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)}
/>
)