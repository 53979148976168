import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksConnected{
          edges {
            node {
             image {
              file {
                url
              }
             },
             w300{
                file{
                 url
                }
             },
             w768 {
                file{
                 url
                }
             }
             title,
             subtitle,
             paragraphs{
                paragraphs
             } 
            }
          }    
        }
     }
    `}
render={data => (
<section data-id="40283a95" className="elementor-element elementor-element-40283a95 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" id="integrations" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" data-element_type="section">
    <div className="elementor-background-overlay"></div>
    <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
            <div data-id="5b8c1b52" className="elementor-element elementor-element-5b8c1b52 elementor-column elementor-col-50 elementor-top-column" data-element_type="column">
                <div className="elementor-column-wrap  elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <div data-id="20d54b33" className="elementor-element elementor-element-20d54b33 elementor-widget elementor-widget-image" data-element_type="image.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-image">
                                    <img width="750" height="689" src={data.allContentfulOuticksConnected.edges[0].node.image.file.url} className="attachment-large size-large" alt={data.allContentfulOuticksConnected.edges[0].node.title} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-id="595ea500" className="elementor-element elementor-element-595ea500 elementor-column elementor-col-50 elementor-top-column" data-element_type="column">
                <div className="elementor-column-wrap  elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <div data-id="1ccbd115" className="elementor-element elementor-element-1ccbd115 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                            <div className="elementor-widget-container">
                                <p className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksConnected.edges[0].node.title} </p>
                            </div>
                        </div>
                        <div data-id="76a5b2da" className="elementor-element elementor-element-76a5b2da elementor-widget elementor-widget-heading" data-element_type="heading.default">
                            <div className="elementor-widget-container">
                                <h3 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksConnected.edges[0].node.subtitle} </h3>
                            </div>
                        </div>
                        <div data-id="59079387" className="elementor-element elementor-element-59079387 elementor-widget elementor-widget-divider" data-element_type="divider.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-divider">
                                    <span className="elementor-divider-separator"></span>
                                </div>
                            </div>
                        </div>
                        <div data-id="17dbf762" className="elementor-element elementor-element-17dbf762 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                            <div className="elementor-widget-container">

                                <div className="elementor-text-editor elementor-clearfix" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksConnected.edges[0].node.paragraphs.paragraphs}} />

                            </div>
                        </div>
                        <div data-id="6be652bb" className="elementor-element elementor-element-6be652bb elementor-mobile-align-justify animated fadeInUp animated-slow elementor-invisible elementor-widget elementor-widget-button" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-element_type="button.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                    <a href="#integrations" className="elementor-button-link elementor-button elementor-size-md" role="button">
                                        <span className="elementor-button-content-wrapper">
                                        <span className="elementor-button-text">Integrations (soon)</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)}
/>
)