import React from "react"

import Header from '../components/header'
import Top from '../components/top'
import Whatwedo from '../components/whatwedo'
import Values from '../components/values'
import Connected from '../components/connected'
import Pricing from '../components/pricing'
import Trial from '../components/trial'
import Footer from '../components/footer'


export default class IndexPage extends React.Component {

componentDidMount() {
  import ('../js/elementor_frontend-modules.js').then(console.log('imported modules'))

  import ('../js/elementor-frontend.min.js').then(console.log('imported frontend'))

}

render(){
return(
<>  
  <div className="wrapper default ">
      <Header />

      <div className="main">
          <article id="post-44" className="section pagebuilder-section">
              <div data-elementor-type="post" data-elementor-id="44" className="elementor elementor-44" data-elementor-settings="[]">
                  <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                          <Top />

                          <Whatwedo />

                          <Values />

                          <Connected />

                          <Pricing />

                          <Trial />

                      </div>
                  </div>
              </div>
          </article>
      </div>

      <Footer />
  </div>

  <script
    dangerouslySetInnerHTML={{
        __html: `
          jQuery(document).ready(function(){jQuery('#menu-item-92 a').addClass('elementor-button-link elementor-button').css('background-color','#C60051').css('color','white');});
          `
    }}
  />

  <button class="hestia-scroll-to-top">
      <i class="fa fa-angle-double-up" aria-hidden="true"></i>
  </button>

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var requestpost={"ajaxurl":"https:\/\/outicks.com\/wp-admin\/admin-ajax.php","disable_autoslide":"","masonry":""};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var elementorFrontendConfig={"environmentMode":{"edit":false,"wpPreview":false},"is_rtl":"","breakpoints":{"xs":0,"sm":480,"md":768,"lg":1025,"xl":1440,"xxl":1600},"version":"2.4.6","urls":{"assets":"https:\/\/outicks.com\/wp-content\/plugins\/elementor\/assets\/"},"settings":{"page":[],"general":{"elementor_global_image_lightbox":"yes","elementor_enable_lightbox_in_editor":"yes"}},"post":{"id":44,"title":"Time Off Management","excerpt":""}};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        COOKIES_ENABLER.init({scriptClass:'ginger-script',iframeClass:'ginger-iframe',acceptClass:'ginger-accept',disableClass:'ginger-disable',dismissClass:'ginger-dismiss',bannerClass:'ginger_banner-wrapper',bannerHTML:document.getElementById('ginger-banner-html')!==null?document.getElementById('ginger-banner-html').innerHTML:'<div class="ginger_banner bottom dialog dark ginger_container ginger_container--open">'+'<p class="ginger_message">'+'<!--googleoff: index-->By using this site, you agree to our updated <a href=\"/privacy-policy\">Privacy Policy<\/a> and our <a href=\"/terms-of-service\">Terms of Use<\/a>.<!--googleon: index-->'+'</p>'+'<a href="#" class="ginger_btn ginger-accept ginger_btn_accept_all">'+'Accept Cookie'+'<\/a>'+'<\/div>',cookieDuration:365000,eventScroll:false,scrollOffset:20,clickOutside:true,cookieName:'ginger-cookie',forceReload:false,iframesPlaceholder:true,iframesPlaceholderClass:'ginger-iframe-placeholder',iframesPlaceholderHTML:document.getElementById('ginger-iframePlaceholder-html')!==null?document.getElementById('ginger-iframePlaceholder-html').innerHTML:'<p><!--googleoff: index-->This website uses cookies.<!--googleon: index-->'+'<a href="#" class="ginger_btn ginger-accept">Accept Cookie</a>'+'<\/p>'});
        `
    }}
  />
</>   
)}

}
