import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksValues{
          edges {
            node {
             title,
             subtitle,
             paragraphs{
                paragraphs
             }
            }
          }    
        }
     }
    `}
render={data => (
<section data-id="47774885" className="elementor-element elementor-element-47774885 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" id="values" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" data-element_type="section">
    <div className="elementor-background-overlay"></div>
    <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
            <div data-id="fed4644" className="elementor-element elementor-element-fed4644 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                <div className="elementor-column-wrap  elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <section data-id="57cf9200" className="elementor-element elementor-element-57cf9200 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                    <div data-id="3174d26d" className="elementor-element elementor-element-3174d26d elementor-column elementor-col-100 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="2b931661" className="elementor-element elementor-element-2b931661 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="243a8139" className="elementor-element elementor-element-243a8139 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <p className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksValues.edges[0].node.title}</p>
                                                    </div>
                                                </div>
                                                <div data-id="431150e3" className="elementor-element elementor-element-431150e3 elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-divider">
                                                            <span className="elementor-divider-separator"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="334754d7" className="elementor-element elementor-element-334754d7 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h3 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksValues.edges[0].node.subtitle}</h3>
                                                    </div>
                                                </div>
                                                <div data-id="43f35bc4" className="elementor-element elementor-element-43f35bc4 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="178f5e01" className="elementor-element elementor-element-178f5e01 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-text-editor elementor-clearfix" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksValues.edges[0].node.paragraphs.paragraphs}} />
                                                    </div>
                                                </div>
                                                <div data-id="d77aeef" className="elementor-element elementor-element-d77aeef elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section data-id="21ad946" className="elementor-element elementor-element-21ad946 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                    <div data-id="264614c3" className="elementor-element elementor-element-264614c3 elementor-column elementor-col-33 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="42c04d8" className="elementor-element elementor-element-42c04d8 elementor-widget elementor-widget-counter" data-element_type="counter.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-counter">
                                                            <div className="elementor-counter-number-wrapper">
                                                                <span className="elementor-counter-number-prefix">+</span>
                                                                <span className="elementor-counter-number" data-duration="2000" data-to-value="28">5</span>
                                                                <span className="elementor-counter-number-suffix">%</span>
                                                            </div>
                                                            <div className="elementor-counter-title">revenue</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-id="74e2b874" className="elementor-element elementor-element-74e2b874 elementor-column elementor-col-33 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="2c74518a" className="elementor-element elementor-element-2c74518a elementor-widget elementor-widget-counter" data-element_type="counter.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-counter">
                                                            <div className="elementor-counter-number-wrapper">
                                                                <span className="elementor-counter-number-prefix">+</span>
                                                                <span className="elementor-counter-number" data-duration="2000" data-to-value="25">18</span>
                                                                <span className="elementor-counter-number-suffix">%</span>
                                                            </div>
                                                            <div className="elementor-counter-title">productivity</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-id="4600e6c9" className="elementor-element elementor-element-4600e6c9 elementor-column elementor-col-33 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="787175c6" className="elementor-element elementor-element-787175c6 elementor-widget elementor-widget-counter" data-element_type="counter.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-counter">
                                                            <div className="elementor-counter-number-wrapper">
                                                                <span className="elementor-counter-number-prefix">-</span>
                                                                <span className="elementor-counter-number" data-duration="2000" data-to-value="55">45</span>
                                                                <span className="elementor-counter-number-suffix">%</span>
                                                            </div>
                                                            <div className="elementor-counter-title">time saved</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)}
/>
)
