import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksTrial{
          edges {
            node {
             rocket {
              file {
                url
              }
             },
             title,
             subtitle 
            }
          }    
        }
     }
    `}
render={data => (
<section data-id="b8786e5" className="elementor-element elementor-element-b8786e5 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" data-element_type="section">
    <div className="elementor-background-overlay"></div>
    <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
            <div data-id="98b585c" className="elementor-element elementor-element-98b585c elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                <div className="elementor-column-wrap  elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <section data-id="dfd88ff" className="elementor-element elementor-element-dfd88ff elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                    <div data-id="1f73a1e" className="elementor-element elementor-element-1f73a1e elementor-column elementor-col-25 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="ce24b0b" className="elementor-element elementor-element-ce24b0b elementor-widget elementor-widget-image" data-element_type="image.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image">
                                                            <img width="458" height="192" src={data.allContentfulOuticksTrial.edges[0].node.rocket.file.url} className="attachment-large size-large" alt="rocket"/> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-id="269a921" className="elementor-element elementor-element-269a921 elementor-column elementor-col-50 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="7e6c261" className="elementor-element elementor-element-7e6c261 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksTrial.edges[0].node.title}</h2>
                                                    </div>
                                                </div>
                                                <div data-id="f306f32" className="elementor-element elementor-element-f306f32 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-text-editor elementor-clearfix">
                                                            <h1 style={{textAlign: 'center'}}><span style={{color: '#ffffff'}}>{data.allContentfulOuticksTrial.edges[0].node.rocket.subtitle}</span></h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-id="b631c31" className="elementor-element elementor-element-b631c31 elementor-column elementor-col-25 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="b30bc2e" className="elementor-element elementor-element-b30bc2e elementor-align-center elementor-widget elementor-widget-button" data-element_type="button.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-button-wrapper">
                                                            <a href="https://app.outicks.com/account/register?plan=team&amp;pos=bottom" className="elementor-button-link elementor-button elementor-size-md elementor-animation-grow" role="button">
                                                                <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-text">Start Free Trial</span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)}
/>
)
