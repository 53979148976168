import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksWhatWeDo{
          edges {
            node {
             title,
             subtitle,
             firstParagraphs{
                firstParagraphs
             },
             image{
              file{
                url
              }
             },
             secondParagraphs{
                secondParagraphs
             },
             secondImage{
              file{
                url
              }
             },
             thirdParagraphs{
                thirdParagraphs
             },
             thirdImage{
              file{
                url
              }
             },
             fourthParagraphs{
                fourthParagraphs
             },
            }
          }    
        }
     }
    `}
render={data => (
<section data-id="3d2fba64" className="elementor-element elementor-element-3d2fba64 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" id="features" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;shape_divider_bottom&quot;:&quot;opacity-fan&quot;}" data-element_type="section">
    <div className="elementor-background-overlay"></div>
    <div className="elementor-shape elementor-shape-bottom" data-negative="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 19.6" preserveAspectRatio="none">
        <path className="elementor-shape-fill" style={{opacity:'0.33'}} d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"/>
        <path className="elementor-shape-fill" style={{opacity:'0.33'}} d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"/>
        <path className="elementor-shape-fill" style={{opacity:'0.33'}} d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"/>
        <path className="elementor-shape-fill" d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"/>
        </svg> 
    </div>
    <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
            <div data-id="1d0b0978" className="elementor-element elementor-element-1d0b0978 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                <div className="elementor-column-wrap  elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <section data-id="37a3a0ea" className="elementor-element elementor-element-37a3a0ea elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                    <div data-id="73073b1c" className="elementor-element elementor-element-73073b1c elementor-column elementor-col-100 elementor-inner-column" data-element_type="column">
                                        <div className="elementor-column-wrap  elementor-element-populated">
                                            <div className="elementor-widget-wrap">
                                                <div data-id="557581ad" className="elementor-element elementor-element-557581ad elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <p className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksWhatWeDo.edges[0].node.title}</p>
                                                    </div>
                                                </div>
                                                <div data-id="5ef8a81" className="elementor-element elementor-element-5ef8a81 elementor-widget elementor-widget-heading" data-element_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h3 className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksWhatWeDo.edges[0].node.subtitle}</h3>
                                                    </div>
                                                </div>
                                                <div data-id="3c9c22ee" className="elementor-element elementor-element-3c9c22ee elementor-widget elementor-widget-divider" data-element_type="divider.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-divider">
                                                            <span className="elementor-divider-separator"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-id="154d2b47" className="elementor-element elementor-element-154d2b47 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-text-editor elementor-clearfix" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksWhatWeDo.edges[0].node.firstParagraphs.firstParagraphs}} />
                                                    </div>
                                                </div>
                                                <div data-id="61aa56bd" className="elementor-element elementor-element-61aa56bd elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div data-id="1add9a76" className="elementor-element elementor-element-1add9a76 obfx-position-top elementor-widget elementor-widget-obfx-services" data-element_type="obfx-services.default">
                            <div className="elementor-widget-container">
                                <div className="obfx-grid">
                                    <div className="obfx-grid-container obfx-grid-mobile-1 obfx-grid-tablet-1 obfx-grid-desktop-3">
                                        <div className="obfx-grid-wrapper">
                                            <div className="obfx-service-box obfx-grid-col">
                                                <span className="obfx-image-wrap"><img className="obfx-image" src={data.allContentfulOuticksWhatWeDo.edges[0].node.image.file.url} alt=""/></span>
                                                <div className="obfx-service-box-content" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksWhatWeDo.edges[0].node.secondParagraphs.secondParagraphs}} />
                                                {/* /.obfx-service-box-content */}
                                            </div>
                                            {/* /.obfx-service-box */}
                                            
                                        </div>
                                        {/* /.obfx-grid-wrapper */}
                                        <div className="obfx-grid-wrapper">
                                            <div className="obfx-service-box obfx-grid-col">
                                                <span className="obfx-image-wrap"><img className="obfx-image" src={data.allContentfulOuticksWhatWeDo.edges[0].node.secondImage.file.url} alt="transparent"/></span>
                                                <div className="obfx-service-box-content" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksWhatWeDo.edges[0].node.thirdParagraphs.thirdParagraphs}} />
                                                {/* /.obfx-service-box-content */}
                                            </div>
                                            {/* /.obfx-service-box */}
                                            
                                        </div>
                                        {/* /.obfx-grid-wrapper */}
                                        <div className="obfx-grid-wrapper">
                                            <div className="obfx-service-box obfx-grid-col">
                                                <span className="obfx-image-wrap"><img className="obfx-image" src={data.allContentfulOuticksWhatWeDo.edges[0].node.thirdImage.file.url} alt="well crafted"/></span>
                                                <div className="obfx-service-box-content" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksWhatWeDo.edges[0].node.fourthParagraphs.fourthParagraphs}} />
                                                {/* /.obfx-service-box-content */}
                                            </div>
                                            {/* /.obfx-service-box */}
                                            
                                        </div>
                                        {/* /.obfx-grid-wrapper */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)}
/>
)