import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksTop{
          edges {
            node {
             title,
             subtitle
            }
          }    
        }
     }
    `}
render={data => (
<section data-id="5bb2a2c4" className="elementor-element elementor-element-5bb2a2c4 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" data-element_type="section">
	<div className="elementor-background-overlay"></div>
	<div className="elementor-container elementor-column-gap-default">
	    <div className="elementor-row">
	        <div data-id="630d73fe" className="elementor-element elementor-element-630d73fe elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
	            <div className="elementor-column-wrap  elementor-element-populated">
	                <div className="elementor-widget-wrap">
	                    <section data-id="58964f67" className="elementor-element elementor-element-58964f67 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
	                        <div className="elementor-container elementor-column-gap-default">
	                            <div className="elementor-row">
	                                <div data-id="5065643a" className="elementor-element elementor-element-5065643a elementor-column elementor-col-100 elementor-inner-column" data-element_type="column">
	                                    <div className="elementor-column-wrap  elementor-element-populated">
	                                        <div className="elementor-widget-wrap">
	                                            <div data-id="452b827b" className="elementor-element elementor-element-452b827b elementor-widget elementor-widget-heading" data-element_type="heading.default">
	                                                <div className="elementor-widget-container">
	                                                    <h2 className="elementor-heading-title elementor-size-default" dangerouslySetInnerHTML={{__html: data.allContentfulOuticksTop.edges[0].node.title}} />
	                                                </div>
	                                            </div>
	                                            <div data-id="4049aa28" className="elementor-element elementor-element-4049aa28 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
	                                                <div className="elementor-widget-container">
	                                                    <div className="elementor-spacer">
	                                                        <div className="elementor-spacer-inner"></div>
	                                                    </div>
	                                                </div>
	                                            </div>
	                                            <div data-id="4b8e5669" className="elementor-element elementor-element-4b8e5669 elementor-widget elementor-widget-heading" data-element_type="heading.default">
	                                                <div className="elementor-widget-container">
	                                                    <p className="elementor-heading-title elementor-size-default">{data.allContentfulOuticksTop.edges[0].node.subtitle}</p>
	                                                </div>
	                                            </div>
	                                            <div data-id="23258970" className="elementor-element elementor-element-23258970 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
	                                                <div className="elementor-widget-container">
	                                                    <div className="elementor-spacer">
	                                                        <div className="elementor-spacer-inner"></div>
	                                                    </div>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </section>
	                    <section data-id="277971aa" className="elementor-element elementor-element-277971aa elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
	                        <div className="elementor-container elementor-column-gap-extended">
	                            <div className="elementor-row">
	                                <div data-id="3c6a9b9a" className="elementor-element elementor-element-3c6a9b9a elementor-column elementor-col-100 elementor-inner-column" data-element_type="column">
	                                    <div className="elementor-column-wrap  elementor-element-populated">
	                                        <div className="elementor-widget-wrap">
	                                            <div data-id="ce04c1a" className="elementor-element elementor-element-ce04c1a elementor-align-center elementor-tablet-align-center elementor-mobile-align-justify animated fadeIn animated-slow elementor-invisible elementor-widget elementor-widget-button" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-element_type="button.default">
	                                                <div className="elementor-widget-container">
	                                                    <div className="elementor-button-wrapper">
	                                                        <a href="#features" className="elementor-button-link elementor-button elementor-size-lg" role="button">
	                                                            <span className="elementor-button-content-wrapper">
	                                                            <span className="elementor-button-text">Learn more</span>
	                                                            </span>
	                                                        </a>
	                                                    </div>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </section>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</section>	
)}
/>
)